import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, Link } from "react-router-dom";
import formatDate from "../../utils/formatDate";

function Event({url}) {
    const [data, setData] = useState([]);
    const [hasLoaded, setHasLoaded] = useState(null);
    const [error, setError] = useState([]);
    let { occurrenceId } = useParams();

    useEffect(() => {
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setData(result.filter(obj => {
                        return obj.OccurrenceId === occurrenceId;
                    }));
                    setHasLoaded(true);
                },
                (error) => {
                    setError(error);
                }
            )
        }, [occurrenceId, url])

        if(hasLoaded) {
            return (
                <EventWrapper>
                    <BackLink onClick={() => window.history.back()}>Tilbage</BackLink>
                    <EventTitle>{data[0]?.Title}</EventTitle>
                    <EventDate>{formatDate(data[0]?.StartDateTime, data[0]?.EndDateTime)}</EventDate>
                    <EventLocation>{data[0]?.Location}</EventLocation>
                    <EventImage src={data[0]?.ImageURL} onError={(event) => event.target.style.display = 'none'}></EventImage>
                    <EventDescription dangerouslySetInnerHTML={{__html: data[0]?.Text}} />
                </EventWrapper>
            )
        } else {
            return <div>Indlæser...</div>;
        }
}

const EventDescription = styled.div`
    padding: 0 0 0 1em;
`;

const EventImage = styled.img`
    max-width: 100%;
    height: auto;
    padding: 1em;
`;

const EventWrapper = styled.div`
    padding: 0.5rem;
`;

const EventTitle = styled.div`
    font-size: 180%;
    margin: 1em 0;
`;

const EventLocation = styled.div`
    padding: 0 0 0 1em;
`;

const EventDate = styled.div`
    font-weight: bold;
    padding: 0 0 0 1em;
`;

const BackLink = styled(Link)`
    color: rgb(181, 74, 16);
`; 

export default Event;