import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
export const ApiDataContext = React.createContext({});

function ApiDataProvider({ children, url }) {
    const [data, setData] = useState([]);
    const { itemsPerPage } = useParams();
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setData(result);
                },
                (error) => {
                    setError(error);
                }
            )
        }, [url])
        
    return (
        <ApiDataContext.Provider value={{ data, error, itemsPerPage }}>
            {children}
        </ApiDataContext.Provider>
    )
}

export default ApiDataProvider;